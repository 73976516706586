.stages__card-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  text-align: center;
}

@media screen and (max-width: 1750px) {
  .stages__card-title  {
    font-size: 20px;
    line-height: 27px;
  }
}

@media screen and (max-width: 1750px) {
  .stages__card-title  {
    font-size: 17px;
    line-height: 25px;
    margin: 20px 10px;
  }
}
