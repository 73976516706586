.main-footer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-height: 217px;
  margin-top: 90px;
}

@media screen and (max-width: 550px) {
  .main-footer  {
    margin-top: 90px;
  }
}