.navigation {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 769px) {
  .navigation {
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: none;
    z-index: 1;
  }
}