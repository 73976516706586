.footer__title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #aba7a7;;
  width: 100%;
  padding: 15px 0;
  margin: 0;
  display: block;
  text-decoration: none;
}

.footer__title:hover {
  border: none;
  cursor: pointer;
}

.footer__title::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-size: cover;
  margin-right: 10px;
}

@media screen and (max-width: 1550px) {
  .footer__title {
    font-size: 12px;
  }
}

@media screen and (max-width: 1023px) {
  .footer__title {
    padding: 6px 0;
    font-size: 10px;
  }
}
