.footer__social-container-item {
  filter: opacity(40%);
  width: 40px;
}

@media screen and (max-width: 1023px) {
  .footer__social-container-item {
    width: 30px;
  }
}
