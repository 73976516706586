.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 63%;
  max-width: 1088px;
  height: 855px;
  background-color: var(--secondary-bg);
  margin: 70px 0px 70px 70px;
}

@media screen and (max-width: 1600px) {
  .partners {
    margin: 70px 0px 70px 50px;
  }
}

@media screen and (max-width: 1550px) {
  .partners {
    margin: 70px 0;
    max-width: 100%;
    width: 93%;
    height: 100%;
  }
}

@media screen and (max-width: 1119px) {
  .partners {
    margin: 50px 0;
    width: 90%;
  }
}