.contact-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 550px;
}

@media screen and (max-width: 1550px) {
  .contact-form  {
    max-width: 700px;
  }
}