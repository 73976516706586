.about {
  width: 100%;
  max-height: 976px;
  display: flex;
  justify-content: space-between;
  padding: 96px var(--1920-resolution-padding) 0;
  box-sizing: border-box;
  background-color: var(--secondary-bg);
}

@media screen and (max-width: 1600px) {
  .about {
    padding: 50px var(--1280-resolution-padding) 0;
    gap: 20px;
  }
}

@media screen and (max-width: 1120px) {
  .about {
    padding: 0 var(--320-resolution-padding-14);
    flex-direction: column;
    align-items: center;
    max-height: 100%;
  }
}