.footer__social-text {
  font-weight: 600;
  font-size: 14px;
  color: #aba7a7;
}

@media screen and (max-width: 1550px) {
  .footer__social-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 670px) {
  .footer__social-text {
    display: none;
  }
}