.main-footer__advantages-text {
  text-shadow: 2px 2px #262323;
  color: var(--primary-text-color);
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.main-footer__advantages-text:before {
  content: '';
  display: inline-block;
  background-image: url(../../../images/round1.png);
  vertical-align: middle;
  width: 22px;
  height: 22px;
  background-size: cover;
  margin-right: 23px;
}

@media screen and (max-width: 1500px) {
  .main-footer__advantages-text {
    font-size: 10px;
  }
}

@media screen and (max-width: 550px) {
  .main-footer__advantages-text:before {
    margin-right: 12px;
  }
}

@media screen and (max-width: 395px) {
  .main-footer__advantages-text:before {
    margin-right: 8px;
  }
}