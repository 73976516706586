.about__title {
  text-shadow: 2px 2px #262323;
  margin: 0;
  font-weight: 500;
  font-size: 26px;
  line-height: 37px;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 1119px) {
  .about__title {
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
  }
}