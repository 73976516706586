.not-found__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding: 5px 0 0;
}

@media screen and (max-width: 420px) {
  .not-found__subtitle {
    padding: 10px 0 0;
    font-size: 12px;
    line-height: 15px;
  }
}
