.services__cards-list {
  display: grid;
  grid-gap: 35px;
  grid-template-columns: repeat(3,minmax(0,1fr));
  padding: 47px 71px 25px;
  max-width: 93%;
}

@media screen and (max-width: 1600px) {
  .services__cards-list {
    grid-template-columns: repeat(2,minmax(0,1fr));
    grid-gap: 40px;
    padding: 47px 50px 25px;
  }
}

@media screen and (max-width: 1119px) {
  .services__cards-list {
    grid-gap: 40px;    
    padding: 47px var(--768-resolution-padding) 25px;
  }
}

@media screen and (max-width: 714px) {
  .services__cards-list {
    grid-template-columns: repeat(1,minmax(0,1fr));
    grid-gap: 30px;   
  }
}

