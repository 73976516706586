.stages__title {
  display: flex;
  justify-content: center;
  align-items:center;
  width: 100%;
  font-weight: 600;
  line-height: 44px;
  font-size: 36px;
  margin: 0;
  padding-bottom: 25px;
}

@media screen and (max-width: 714px) {
  .stages__title {
    text-align: center;
    line-height: 38px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .stages__title {
    text-align: center;
    line-height: 36px;
    font-size: 28px;
  }
}