.request-block__title {
  text-align: center;
  padding: 78px 70px 24px;
  margin: 0;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  max-width: 460px;
}

@media screen and (max-width: 1550px) {
  .request-block__title  {
    max-width: 700px;
  }
}

@media screen and (max-width: 750px) {
  .request-block__title  {
    font-size: 20px;
    line-height: 28px;
    padding-top: 50px;
  }
}