.promo__subtitle {    
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -.04em;
  line-height: 55px;
  max-width: 1181px;
  text-align: center;
  margin: 10px 0 0;
  text-shadow: 2px 2px #262323;
}


@media screen and (max-width: 1600px) {
  .promo__subtitle {
    font-size: 32px;
    line-height: 48px;
  }
}

@media screen and (max-width: 1024px) {
  .promo__subtitle {
    font-size: 28px;
    line-height: 46px;
  }
}


@media screen and (max-width: 900px) {
  .promo__subtitle {
    width: 90%;
    font-size: 24px;
  }
}

@media screen and (max-width: 550px) {
  .promo__subtitle {
    font-size: 22px;
    line-height: 40px;
  }
}