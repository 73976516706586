.subscription__text-container-title {
  padding: 0;
  margin: 0;
  font-size: 34px;
  line-height: 41px;
  font-weight: 600;
}

@media screen and (max-width: 1119px) {
  .subscription__text-container-title  {
    width: 90%;
    font-size: 30px;
    line-height: 38px;
  }
}