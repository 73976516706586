.navigation__background {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
}

@media screen and (max-width: 769px) {
  .navigation__background {
    background-color: rgba(23, 21, 21, 0.7);
  }
}