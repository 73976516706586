.services__card {
  display: flex;
  flex-direction: column;
  background-color: #202124;
  height: 470px;
}

@media screen and (max-width: 860px) {
  .services__card {
    height: 530px;
  }
}

@media screen and (max-width: 714px) {
  .services__card {
    height: 400px;
  }
}

@media screen and (max-width: 350px) {
  .services__card {
    height: 420px;
  }
}

