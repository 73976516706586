.navigation__close-button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: none;
  border: transparent;
  position: absolute;
  top:23px;
  right: 23px;
  width: 32px;
  height: 32px;
  background-image: url(../../../images/burger_menu_close.svg);
  z-index: 5;
}

@media screen and (max-width: 769px) {
  .navigation__close-button {
    display: flex;
  }
}

@media screen and (max-width: 420px) {
  .navigation__close-button {
    top:14px;
    right: 14px;
  }
}
