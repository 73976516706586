.logo-text__subtitle {
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
  line-height: 10px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1600px) {
  .logo-text__subtitle  {
    font-size: 9.5px;
  }
}

@media screen and (max-width: 1400px) {
  .logo-text__subtitle  {
    font-size: 7.5px;
  }
}

@media screen and (max-width: 1023px) {
  .logo-text__subtitle {
    font-size: 6.5px;
  }
}