.promo__right-button {
  background-color: transparent;
  color: var(--primary-text-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 1024px) {
  .promo__right-button  {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .promo__right-button  {
    font-size: 10px;
  }
}