.main-footer__advantages {
  display: flex;
  gap: 75px;
  justify-content: center;
  width: 100%;
  padding: 104px 0 80px;
}

@media screen and (max-width: 1750px) {
  .main-footer__advantages {
    gap: 35px;
  }
}

@media screen and (max-width: 900px) {
  .main-footer__advantages {
    gap: 12px;
    flex-direction: column;
    padding: 0 30px 20px;
  }
}

@media screen and (max-width: 450px) {
  .main-footer__advantages {
    gap: 5px;
  }
}