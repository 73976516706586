.subscription__text-container-subtitle {
  font-size: 18px;
  line-height: 22px;
  font-style: italic;
  font-weight: 400;
}

@media screen and (max-width: 1119px) {
  .subscription__text-container-subtitle  {
    width: 90%;
    max-width: 550px;
    font-size: 14px;
    line-height: 18px;
  }
}