.services__pic {
  width: 68px;
  height: 68px;
  padding: 35px 0 0 45px;
}

@media screen and (max-width: 1023px) {
  .services__pic {
    width: 55px;
    height: 55px;
  }
}

@media screen and (max-width: 546px) {
  .services__pic {
    width: 50px;
    height: 50px;
    padding: 20px 0 0 30px;
  }
}

