.about__article {
  display: flex;
  flex-direction: column;
  max-width: 58%;
  align-items: center;
  background-image: url(../../../images/round-orange.png);
  background-size: cover;
}

@media screen and (max-width: 1119px) {
  .about__article {
      padding-top: 20px;
      max-width: 1060px;
      width: 94.5%;
  }
}