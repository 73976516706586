.promo__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
  padding: 110px 0 0;
}

@media screen and (max-width: 1400px) {
  .promo__buttons {
    padding: 50px 0 0;
  }
}

@media screen and (max-width: 550px) {
  .promo__buttons {
    flex-direction: column;
  }
}

@media screen and (max-width: 420px) {
  .promo__buttons {
    padding-top: 90px;
  }
}