.navigation__burger-button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: none;
  border: transparent;
}

@media screen and (max-width: 769px) {
  .navigation__burger-button {
    display: flex;
  }
}
