.logo__first-letter {
  font-size: 55px;
}


@media screen and (max-width: 1600px) {
  .logo__first-letter  {
    font-size: 49px;
  }
}

@media screen and (max-width: 1400px) {
  .logo__first-letter  {
    font-size: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .logo__first-letter  {
    font-size: 36px;
  }
}