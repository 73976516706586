.services__title {
  background-color: var(--secondary-bg);
  display: flex;
  justify-content: center;
  align-items:center;
  width: 100%;
  font-weight: 600;
  line-height: 44px;
  font-size: 36px;
  margin: 0;
  padding: 55px 0 25px;
}

@media screen and (max-width: 714px) {
  .services__title {
    line-height: 38px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .services__title {
    line-height: 36px;
    font-size: 28px;
  }
}
