.main-footer__empty-container {
  width: 93px;
  height: 93px;
  margin: 0 188px 16px 0;
  align-items: flex-end;
  display: flex;
}

@media screen and (max-width: 1400px) {
  .main-footer__empty-container {
    margin-right: 120px;
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1100px) {
  .main-footer__empty-container {
    margin-right: 70px;
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .main-footer__empty-container {
    margin-right: 30px;
  }
}