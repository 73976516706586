.logo-text__title {
  align-self: self-end;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 1px;
  padding: 0;
  height: 50px;
  /* text-transform: uppercase; */
}


@media screen and (max-width: 1600px) {
  .logo-text__title  {
    font-size: 38px;
  }
}

@media screen and (max-width: 1400px) {
  .logo-text__title  {
    font-size: 30px;
  }
}

@media screen and (max-width: 1023px) {
  .logo-text__title {
    font-size: 26px;
  }
}

@media screen and (max-width: 768px) {
  .logo-text__title {
    display: none;
  }
}