.header__burger-button {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: none;
  border: transparent;
  width: 44px;
  height: 44px;
  background-image: url(../../../images/burger-icon-open.svg);
  opacity: 1;
  align-self: center;
}


@media screen and (max-width: 769px) {
  .header__burger-button {
    display: flex;
  }
}

@media screen and (max-width: 420px) {
  .header__burger-button  {
    width: 40px;
    height: 40px;
  }
}
