.navigation__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

  @media screen and (max-width: 1750px) {
    .navigation__container {
      gap: 30px;
    }
  }
  
  @media screen and (max-width: 769px) {
    .navigation__container {
      position: relative;
      display: flex;
      height: 100%;
      width: 50%;
      max-width: 520px;
      flex-direction: column;
      align-items: center;
      background-color: var(--primary-bg);
      box-sizing: border-box;
      padding: 159px 0 90px;
      /* left: 50%; */
    }
}

@media screen and (max-width: 420px) {
  .navigation__container {
    padding: 144px 0 46px;
  }
}
