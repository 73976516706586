.promo__title {
  text-shadow: 2px 2px #262323;
  color: var(--primary-text-color);
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -.04em;
  line-height: 59px;
  max-width: 988px;
  text-align: center;
  margin: 240px 0 0;
}

@media screen and (max-width: 1600px) {
  .promo__title {
    font-size: 40px;
    line-height: 51px;
  }
}

@media screen and (max-width: 1024px) {
  .promo__title {
    font-size: 32px;
    line-height: 44px;
  }
}

@media screen and (max-width: 900px) {
  .promo__title {
    margin-top: 120px;
    width: 90%;
    font-size: 28px;
    line-height: 40px;
  }
}

@media screen and (max-width: 550px) {
  .promo__title {
    /* margin-top: 50px; */
  }
}

