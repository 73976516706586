.constants {
  --primary-bg: #463D3B;
  --primary-variant-bg: #38312F;
  --secondary-bg: #383130;
  --secondary-bg-variant: #414040;

  --accent-color: #F69020;
  --secondary-accent-color: #337555;

  --primary-text-color: #FFFFFF;
  --secondary-text-color: #000000;
  --secondary-text-color-variant: #bbbbbb;

  --primary-underline-color: #DADADA;
  --secondary-underline-color: #424242;
  --secondary-underline-color-variant: #AAAAAA;

  --hovered-transition: opacity .2s linear;
  --hovered-opacity: .6;

  --1920-resolution-padding: 69px;

  --1280-resolution-padding: 50px;
  --768-resolution-padding: 30px;

  --320-resolution-padding-14: 14px;
  --320-resolution-padding-18: 18px;
  --320-resolution-padding-footer: 10px;
  /* --secondary-accent-color: #0DA65C; */
}
