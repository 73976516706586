.social__mark {
  width: 44px;
  height: 44px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-size: cover;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease, visibility 2s ease;
}