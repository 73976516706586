.stages__cards-list {
  background-color: var(--secondary-bg);
  display: grid;
  grid-gap: 60px;
  grid-template-columns: repeat(4,minmax(0,1fr));
  padding: 47px 71px 47px;
  background-image: url(../../../images/round-green.png);
  background-size: cover;
}


@media screen and (max-width: 1720px) {
  .stages__cards-list  {
    background-image: none;
  }
}
@media screen and (max-width: 1600px) {
  .stages__cards-list  {
    padding: 47px var(--1280-resolution-padding) 47px;
  }
}

@media screen and (max-width: 1500px) {
  .stages__cards-list  {
    grid-template-columns: repeat(2,minmax(0,1fr));
    
  }
}

@media screen and (max-width: 1119px) {
  .stages__cards-list  {
    grid-gap: 60px;
    padding: 47px var(--768-resolution-padding) 47px;
  }
}


@media screen and (max-width: 714px) {
  .stages__cards-list  {
    grid-template-columns: repeat(1,minmax(0,1fr));
    grid-gap: 30px;
  }
}