.not-found__title {
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
  margin: 0;
  padding: 246px 0 0;
}

@media screen and (max-width: 769px) {
  .not-found__title {
    padding: 408px 0 0;
  }
}

@media screen and (max-width: 420px) {
  .not-found__title {
    padding: 329px 0 0;
    font-size: 80px;
    line-height: 97px;
  }
}