.logo__pic {
  display: flex;
  width: 120px;
  height: 120px;
}

@media screen and (max-width: 1600px) {
  .logo__pic {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 1400px) {
  .logo__pic {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1023px) {
  .logo__pic {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 900px) {
  .logo__pic {
    width: 50px;
    height: 50px;
  }
}