.sundry {
  display: flex;
  align-items: center;
  gap: 42px;
  width: 100%;
  max-width: 1920px;
}


@media screen and (max-width: 1550px) {
  .sundry {
    flex-direction: column;
    gap: initial;
  }
}