.footer__title-mail::before {
  background-image: url(../../../images/footer-mail.png);
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-size: cover;
}

