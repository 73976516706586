.contact-form__label {
  gap: 7px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 17px 0 0;
}

.contact-form__label:first-of-type{
padding: 0;
}