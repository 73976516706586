.scroll-button {
  position: fixed;
  /* right: 5vh; */
  /* width: min(7vw, 7vh); */
  /* height: min(7vw, 7vh); */
  height: 30px;
  width: 30px;
  right: 180px;
  bottom: 10px;
  z-index: 1;
  cursor: pointer;
  background-image: url(../../images/arrow-up.svg);
  border: none;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0;
}

@media screen and (max-width: 1550px) {
  .scroll-button {
    right: 170px;
  }
}


@media screen and (max-width: 1400px) {
  .scroll-button  {
    right: 140px;
  }
}

@media screen and (max-width: 1100px) {
  .scroll-button  {
    right: 80px;
  }
}

@media screen and (max-width: 600px) {
  .scroll-button  {
    right: 45px;
  }
}