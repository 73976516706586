.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../images/main_picture.png);
  width: 100%;
  background-repeat: no-repeat;
  max-height: 989px;
}

@media screen and (max-width: 550px) {
  .main  {
    max-height: 896px;
  }
}