.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 126px;
  box-sizing: border-box;
  margin-top: 20px;
}

@media screen and (max-width: 1600px) {
  .header  {
    padding: 0 var(--1280-resolution-padding);
    height: 80px;
  }
}

@media screen and (max-width: 1023px) {
  .header  {
    padding: 0 var(--768-resolution-padding);
    height: 60px;
  }
}