.logo-text {
  display: flex;
  flex-direction: column;
  height: 66px;
}

@media screen and (max-width: 900px) {
  .logo-text {
    display: none;
  }
}