.about__text {
  margin: 0;
  font-weight: 500;
  font-size: 23px;
  line-height: 37px;
  letter-spacing: 1px;
  margin-bottom: 55px;
  align-items: center;
  justify-content: center;
}


.about__text:last-child {
  margin-bottom: 0;
}


@media screen and (max-width: 1700px) {
  .about__text {
    font-size: 20px;
    line-height: 33px;
  }
}

@media screen and (max-width: 1600px) {
  .about__text {
    font-size: 18px;
    line-height: 27px;
  }
}

@media screen and (max-width: 1400px) {
  .about__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 55px;
  }
}


@media screen and (max-width: 1280px) {
  .about__text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1119px) {
  .about__text {
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
  }
}


