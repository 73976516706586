.contact-form__button {
  margin-top: 90px;
  height: 98px;
  background-color: var(--accent-color);
  color: var(--primary-text-color);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
}

@media screen and (max-width: 433px) {
  .contact-form__button{
    height: 88px;
  }
}