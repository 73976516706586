.request-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 37%;
  height: 855px;
  max-width: 650px;
  background-color: #383231;
  margin: 70px 70px 70px 0px;
}

@media screen and (max-width: 1600px) {
  .request-block {
    margin: 70px 50px 70px 0px;
  }
}

@media screen and (max-width: 1550px) {
  .request-block {
    margin: 0px 50px;
    max-width: 1450px;
    width: 93%;
  }
}

@media screen and (max-width: 1119px) {
  .request-block {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .request-block {
    height: 800px;
    width: 90%;
  }
}