.header__menu {
  align-items: baseline;
  display: flex;
  gap: 50px;
}

@media screen and (max-width: 769px) {
  .header__menu{
    align-items: center;
  }
}

@media screen and (max-width: 420px) {
  .header__menu{
    gap: 20px;
  }
}
