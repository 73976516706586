.stages__pic {
  height: 100px;
  max-width: 100px;
  margin-top: 20px;
}

@media screen and (max-width: 1550px) {
  .stages__pic   {
    height: 80px;
    max-width: 80px;
  }
}

@media screen and (max-width: 714px) {
  .stages__pic   {
    margin-top: 0px;
  }
}