.services__card-text {
  max-width:450px;
  padding: 15px 20px 0 45px;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}


@media screen and (max-width: 1599px) {
  .services__card-text {
    max-width: 90%;
  }
}

@media screen and (max-width: 1280px) {
  .services__card-text {
    max-width:550px;
  }
}

@media screen and (max-width: 1023px) {
  .services__card-text {    
    font-size: 13px;
    line-height: 23px;
  }
}

@media screen and (max-width: 546px) {
  .services__card-text {    
    font-size: 12px;
    line-height: 21px;
    padding: 15px 12px 0 30px;
  }
}

@media screen and (max-width: 400px) {
  .services__card-text {    
    font-size: 11px;
    line-height: 20px;
    padding-top: 10px;
  }
}
