.subscription__form-container {
  display: flex;
  padding: 70px 0 0 193px;
  max-width: 810px;
  gap: 18px;
}

@media screen and (max-width: 1550px) {
  .subscription__form-container  {
    padding: 70px 0 0 var(--1280-resolution-padding);
  }
}

@media screen and (max-width: 1119px) {
  .subscription__form-container  {
    padding: 70px 0 0 var(--768-resolution-padding);
  }
}

@media screen and (max-width: 1023px) {
  .subscription__form-container  {
    max-width: 550px;
  }
}


@media screen and (max-width: 600px) {
  .subscription__form-container  {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 20px;
    padding-left: 0;
  }
}