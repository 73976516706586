.navigation__item {
  text-shadow: 2px 2px #262323;
  display: flex;
  margin: 0;
  font-weight: 700;
  font-size: 17px;
  padding-bottom: 6px;
  text-transform: uppercase;
  color: var(--primary-text-color);
}

@media screen and (max-width: 1400px) {
  .navigation__item  {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .navigation__item  {
    font-size: 12px;
  }
}