.services__card-text_yellow {
  color: var(--accent-color);
}

/* .services__card-text_yellow::before {
  content: '';
  display: inline-block;
  background-image: url(../../../../images/check-mark.png);
  width: 14px;
  height: 14px;
  background-size: cover;
  margin-right: 30px;
} */