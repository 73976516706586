.header__number {
  text-shadow: 2px 2px #262323;
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  display: flex;
  align-items: baseline;
  text-decoration: none;
  color: var(--primary-text-color);
}

/* .header__number::before {
  background-image: url(../../../images/phone_header.png);
  width: 50px;
  height: 50px;
  content: '';
  display: inline-block;
  margin-right: 9px;
  background-size: cover;
} */

@media screen and (max-width: 1400px) {
  .header__number  {
    font-size: 20px;
  }
}

/* @media screen and (max-width: 1400px) {
  .header__number::before {
    width: 30px;
    height: 30px;
  }
} */

@media screen and (max-width: 1023px) {
  .header__number {
      font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
  .header__number {
    /* padding-bottom: 10px; */
    }
}


@media screen and (max-width: 500px) {
  .header__number {
      align-self: center;
    }
}

@media screen and (max-width: 420px) {
  .header__number {
      font-size: 16px;
      padding-left: 10px;
    }
}

@media screen and (max-width: 500px) {
  .header__number::before {
    display: none;
  }
}

