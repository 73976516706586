.stages__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #413B39;
  height: 390px;
}

@media screen and (max-width: 1500px) {
  .stages__card {
    height: 370px;
  }
}

@media screen and (max-width: 714px) {
  .stages__card {
    height: 320px;
  }
}