.services__card-title {
  display: flex;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  padding: 35px 20px 0 45px;
  margin: 0;
}


@media screen and (max-width: 714px) {
  .services__card-title {
    padding-top: 20px;
  }
}

@media screen and (max-width: 546px) {
  .services__card-title {
    font-size: 18px;
    padding: 17px 15px 0 30px;
  }
}