.subscription__text-container {
  display: flex;
  flex-direction: column;
  padding: 131px 0 0 193px;
  max-width: 810px;
}

@media screen and (max-width: 1550px) {
  .subscription__text-container  {
    padding: 120px 0 0 50px;
  }
}

/* @media screen and (max-width: 1400px) {
  .subscription__text-container  {
    padding: 100px 0 0 50px;
  }
} */

@media screen and (max-width: 1119px) {
  .subscription__text-container {
    padding: 120px 0 0 var(--768-resolution-padding);
  }
}

@media screen and (max-width: 600px) {
  .subscription__text-container {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
}