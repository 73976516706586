.subscription {
  background-image: url(../../images/subscription-pic.png);
  height: 504px;
  max-width: 1920px;
  width: 100%;
  box-sizing: border-box;
}


@media screen and (max-width: 1550px) {
  .subscription {
    margin-top: 50px;
  }
}

@media screen and (max-width: 639px) {
  .subscription {
    height: 600px;
  }
}