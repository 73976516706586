.footer__contacts {
  display: flex;
  gap: 25px;
  max-width: 800px;
  white-space: nowrap;
}

@media screen and (max-width: 1550px) {
  .footer__contacts  {
    gap: 20px;
  }
}


@media screen and (max-width: 1199px) {
  .footer__contacts {
    flex-direction: column;
    gap: 6px;
  }
}

@media screen and (max-width: 480px) {
  .footer__contacts {
    text-align: center;

  }
}
