.chat {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  right: 150px;
  bottom: 90px;
  align-items: center;
  z-index: 10;
  /* width: 93px;
  height: 93px; */
  /* margin: 0 188px 16px 0;
  align-items: flex-end; */
}

@media screen and (max-width: 1400px) {
  .chat {
    right: 120px;
    /* width: 70px;
    height: 70px; */
  }
}

@media screen and (max-width: 1100px) {
  .chat {
    right: 70px;
    /* width: 60px;
    height: 60px; */
  }
}

@media screen and (max-width: 600px) {
  .chat {
    right: 30px;
  }
}