.main-footer__container {
  display: flex;
  flex-direction: column;
  width: 22px;
  margin: 42px 0 0 222px;
  gap: 32px;
  padding: 0 0 40px;
}


@media screen and (max-width: 1400px) {
  .main-footer__container {
    margin-left: 120px;
  }
}

@media screen and (max-width: 1100px) {
  .main-footer__container {
    margin-left: 70px;
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 900px) {
  .main-footer__container {
    display: none;
  }
}