.footer__social-container {
  display: flex;
  gap: 15px;
  align-items: center;
}


@media screen and (max-width: 1023px) {
  .footer__social-container {
    gap: 10px;
  }
}

@media screen and (max-width: 670px) {
  .footer__social-container {
    padding-top: 20px;
  }
}