.contact-form__input {
  height: 78px;
  border-radius: 10px;
  background-color:  rgba(54, 102, 109, 0.49);
  border-color: #086a3b;
  padding-left: 15px;
  color: var(--primary-text-color);
  border-style: double;
}

@media screen and (max-width: 433px) {
  .contact-form__input {
    height: 65px;
  }
}