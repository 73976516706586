.navigation__burger-button_type_close {
  position: absolute;
  top:23px;
  right: 23px;
  width: 32px;
  height: 32px;
  background-image: url(../../../../images/burger_menu_close.svg);
}

@media screen and (max-width: 420px) {
  .navigation__burger-button_type_close {
    top:14px;
    right: 14px;
  }
}
