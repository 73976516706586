.footer {
  width: 100%;
  max-width: 1920px;
  height: 120px;
  background-color: var(--primary-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0;
  padding: 0 var(--1920-resolution-padding);
}

@media screen and (max-width: 1600px) {
  .footer {
    padding: 0 var(--1280-resolution-padding);
  }
}


@media screen and (max-width: 1199px) {
  .footer {
    height: 170px;
  }
}

@media screen and (max-width: 1119px) {
  .footer {
    padding: 0 var(--768-resolution-padding);
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    flex-direction: column;
    padding: 25px;
  }
}

