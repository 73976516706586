.partners__item {
  max-width: 197px;
  height: 118px;
}

@media screen and (max-width: 1350px) {
  .partners__item {
    max-width: 170px;
    height: 102px;
  }
}

@media screen and (max-width: 1170px) {
  .partners__item {
    max-width: 143px;
    height: 86px;
  }
}

@media screen and (max-width: 1000px) {
  .partners__item {
    max-width: 110px;
    height: 67px;
  }
}

@media screen and (max-width: 800px) {
  .partners__item {
    max-width: 143px;
    height: 86px;
  }
}

@media screen and (max-width: 700px) {
  .partners__item {
    max-width: 110px;
    height: 67px;
  }
}

@media screen and (max-width: 450px) {
  .partners__item {
    max-width: 94px;
    height: 54px;
  }
}

@media screen and (max-width: 370px) {
  .partners__item {
    max-width: 80px;
    height: 49px;
  }
}
