.stages__card-text {
  text-align: center;
  max-width: 90%;
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

@media screen and (max-width: 1550px) {
  .stages__card-text {
    font-size: 12px;
    line-height: 21px;
  }
}