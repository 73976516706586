.chat__mark {
  background-image: url(../../../images/chat-mark.png);
  width: 93px;
  height: 93px;
  right: 20px;
  bottom: 0;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  z-index: 11;
}

@media screen and (max-width: 1550px) {
  .chat__mark {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 1100px) {
  .chat__mark {
    width: 60px;
    height: 60px;
  }
}