.app {
  width: 100%;
  height: 100%;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
}
