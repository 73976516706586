.navigation__burger-button_type_open {
  width: 44px;
  height: 44px;
  background-image: url(../../../../images/burger-icon-open.svg);
}

@media screen and (max-width: 420px) {
  .navigation__burger-button_type_open {
    width: 40px;
    height: 43px;
  }
}
