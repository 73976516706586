@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/_montserrat/Montserrat-Regular.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/_montserrat/Montserrat-Medium.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/_montserrat/Montserrat-SemiBold.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/_montserrat/Montserrat-Bold.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/_montserrat/Montserrat-ExtraBold.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/_montserrat/Montserrat-Italic.woff2) format('woff2'),
       url(../fonts/_montserrat/Montserrat-Italic.woff) format('woff');
}
