.subscription__form-container-button {
  width: 298px;
  height: 73px;
  background-color: rgba(246, 144, 32, 0.85);
  color: var(--primary-text-color);
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
}

@media screen and (max-width: 1023px) {
  .subscription__form-container-button  {
    font-size: 16px;
    line-height: 20px;
    height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .subscription__form-container-button  {
    width: 50%;
  }
}