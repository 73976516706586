.promo__button-style {
  transform: translateZ(0);
  border-radius: 5px;
  width: 350px;
  height: 80px;
  border: 1px solid var(--accent-color);
  outline: 1px solid #F69020;
  outline-offset: 7px;
  color: var(--primary-text-color);
  -webkit-appearance:calc();
}

@media screen and (max-width: 1600px) {
  .promo__button-style   {
    width: 280px;
    height: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .promo__button-style   {
    width: 210px;
    height: 55px;
  }
}


@media screen and (max-width: 550px) {
  .promo__button-style   {
    width: 190px;
    height: 50px;
  }
}