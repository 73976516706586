.subscription__form-container-input {
  width: 490px;
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.85);
  
  padding: 25px 25px 25px 13px;
}

.subscription__form-container-input::placeholder {
  color: var(--secondary-text-color-variant);
  font-style: italic;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1023px) {
  .subscription__form-container-input {
    padding: 20px 20px 20px 13px;
  }
}

@media screen and (max-width: 600px) {
  .subscription__form-container-input {
    display: flex;
    align-items: center;
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .subscription__form-container-input {
    display: flex;
    align-items: center;
    width: 70%;
  }
}
