.partners__list {
  display: grid;
  grid-gap: 45px;
  grid-template-columns: repeat(4,minmax(0,1fr));
  padding: 38px 77px 0px;
}

@media screen and (max-width: 1800px) {
  .partners__list {
    grid-template-columns: repeat(3,minmax(0,1fr));
    padding: 38px 38px 0px;
  }
}

@media screen and (max-width: 1550px) {
  .partners__list {
    grid-template-columns: repeat(6,minmax(0,1fr));
    padding: 38px;
    grid-gap: 25px;
  }
}

@media screen and (max-width: 800px) {
  .partners__list {
    grid-template-columns: repeat(4,minmax(0,1fr));
    padding: 38px;
    grid-gap: 25px;
  }
}

@media screen and (max-width: 550px) {
  .partners__list {
    grid-template-columns: repeat(3,minmax(0,1fr));
    padding: 20px;
    grid-gap: 20px;
  }
}