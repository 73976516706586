.partners__title {
  padding-top: 78px;
  margin: 0;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}


@media screen and (max-width: 1550px) {
  .partners__title {
    padding-top: 20px;
    font-size: 36px;
    line-height: 44px;
    background-color: #463D3B;
    width: 100%;
    text-align: center;
    padding-bottom: 25px;
    padding-top: 0;
  }
}


@media screen and (max-width: 714px) {
  .partners__title {
    line-height: 38px;
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .partners__title {
    line-height: 36px;
    font-size: 28px;
  }
}